module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://use.typekit.net"],"web":[{"name":"arboteck","file":"https://use.typekit.net/ofg7kyr.css"},{"name":"fenway-park-jf","file":"https://use.typekit.net/ofg7kyr.css"},{"name":"proxima-nova","file":"https://use.typekit.net/ofg7kyr.css"},{"name":"roc-grotesk-wide","file":"https://use.typekit.net/ofg7kyr.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
